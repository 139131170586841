import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "メドレーが協賛予定のイベントをご紹介します。（2019/7~）",
  "date": "2019-06-26T07:17:48.000Z",
  "slug": "entry/2019/06/26/161748",
  "tags": ["medley"],
  "hero": "./2019_06_26.png",
  "heroAlt": "協賛イベント"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。開発本部エンジニアの平木です。`}</p>
    <p>{`メドレーでは、技術や業界の発展に少しでも寄与できればという考えから、エンジニア・デザイナーの技術イベントなどに、積極的に協賛させていただきたいと考えています。7 月以降のイベントも積極的にスポンサードさせていただきますので、ぜひ皆様にもお越しいただきたく、このエントリではメドレーが協賛するイベントの魅力をご紹介します。`}</p>
    <h1>{`Developers Summit 2019 Summer`}</h1>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190620/20190620190529.png",
      "alt": "20190620190529.png"
    }}></img>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://event.shoeisha.jp/devsumi/20190702"
        }}>{`公式サイト`}</a></li>
      <li parentName="ul">{`2019/07/02(火) @ソラシティカンファレンスセンター`}</li>
      <li parentName="ul">{`ブロンズスポンサー`}</li>
    </ul>
    <p>{`一番最初にご紹介するのは、ご存知 Developers Summit 2019 Summer(以降デブサミ夏)です。`}</p>
    <p>{`本家である Developers Summit 2019 冬でも協賛させていただきましたが、今回のデブサミ夏ではブロンズスポンサーとして`}<a parentName="p" {...{
        "href": "https://www.medley.jp/team/creator-story-tanaka.html"
      }}>{`執行役員である田中`}</a>{`が`}<a parentName="p" {...{
        "href": "https://event.shoeisha.jp/devsumi/20190702/session/2089/"
      }}>{`SI × Web の総合力で切り拓く新しいエンジニアのキャリアパス`}</a>{`というタイトルでお話します。`}</p>
    <p>{`ここ数年で`}<strong parentName="p">{`X-Tech`}</strong>{`や`}<strong parentName="p">{`DX`}</strong>{`の必要性が一気に叫ばれるようになってきましたが、メドレーでも`}<a parentName="p" {...{
        "href": "https://www.medley.jp/approach/medical-dx.html"
      }}>{`医療におけるデジタルトランスフォーメーションの推進`}</a>{`を目指して日々プロダクトの開発を進めています。X-Tech や DX を推進する上で、いわゆる「Web 系エンジニア」と「SI 系エンジニア」という垣根を越えた、ハイブリッドな新しいエンジニア像が求められるようになってきたと感じます。「課題を解決するために必要とされるようになったハイブリットな能力とは?」「これからのエンジニアはどう成長していくのがよいか?」ということをお話させていただきます。`}</p>
    <p>{`おかげさまで、現時点でセッションは満員になっていますが、ぜひご覧いただければ幸いです。`}</p>
    <h1>{`CloudNativeDays Tokyo 2019 / OpenStack Days Tokyo 2019`}</h1>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190620/20190620190716.png",
      "alt": "20190620190716.png"
    }}></img>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cloudnativedays.jp/cndt2019/"
        }}>{`公式サイト`}</a></li>
      <li parentName="ul">{`2019/07/22(月) ~ 23(火) @虎ノ門ヒルズフォーラム`}</li>
      <li parentName="ul">{`ノベルティスポンサー(トートバッグ)`}</li>
    </ul>
    <p>{`今年はイベントの統合もされ、名称も一新された日本最大級のコンテナ技術を始めとしたクラウドネイティブとオープンインフラの祭典である、こちらのイベントにも協賛させていただきます。`}</p>
    <p>{`当日会場で配られるトートバッグに公式ロゴと共にメドレーのロゴを入れていただきました。実際にどんなデザインになるかは当日にご来場していただくまでのお楽しみとさせていただきますが、とても良いものになっているかと思います。`}</p>
    <h1>{`builderscon tokyo 2019`}</h1>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190620/20190620190740.png",
      "alt": "20190620190740.png"
    }}></img>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://builderscon.io/tokyo/2019"
        }}>{`公式サイト`}</a></li>
      <li parentName="ul">{`2019/08/29(木) ~ 31(土) @東京電機大学(東京千住キャンパス)1 号館`}</li>
      <li parentName="ul">{`バックパネルスポンサー`}</li>
    </ul>
    <p>{`2016 年から毎年開催されている、色々な分野のエンジニアの様々なセッションが一気に聞けるイベント「builderscon tokyo」ですが(去年は電子名札バッジがインパクトありましたね)、今年は初めてスポンサーとして参加させていただくことになりました。`}</p>
    <p>{`会場である東京電機大学千住キャンパスの部屋の一つ、100 周年記念ホールでセッションするスピーカーさんの後ろに設置されるバックパネルのなかに、メドレーのロゴが入ることに。`}</p>
    <p>{`こちらのイベントでは弊社エンジニアもお邪魔する予定ですので、会場でお気軽にお声がけしていただければと思います。`}</p>
    <h1>{`CODE BLUE 2019`}</h1>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190620/20190620190759.png",
      "alt": "20190620190759.png"
    }}></img>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://codeblue.jp/2019/"
        }}>{`公式サイト`}</a></li>
      <li parentName="ul">{`2019/10/29(火) ~ 30(水) @ベルサール渋谷ガーデン`}</li>
      <li parentName="ul">{`ブロンズスポンサー`}</li>
    </ul>
    <p>{`今年で 7 回目の開催となる情報セキュリティの国際会議「CODE BLUE 2019」に初めてスポンサーをさせていただいています。`}</p>
    <p>{`メドレーでも取り扱っている情報の重要性から、会社としての取り組みで`}<a parentName="p" {...{
        "href": "/entry/2019/02/01/172457"
      }}>{`ISMS 認証 / ISMS クラウドセキュリティ認証を取得`}</a>{`していますが、このようなセキュリティに関するイベントにも業界の発展に少しでも寄与できればと今年から協賛させていただくことになりました。`}</p>
    <h1>{`DesginShip 2019`}</h1>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190620/20190620190818.png",
      "alt": "20190620190818.png"
    }}></img>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://design-ship.jp/"
        }}>{`公式サイト`}</a></li>
      <li parentName="ul">{`2019/11/23(土 ) ~ 24(日) @東京国際フォーラム B7 ・ B5`}</li>
      <li parentName="ul">{`シルバースポンサー`}</li>
    </ul>
    <p>{`去年から開催されているデザインカンファレンス「DesignShip 2019」に、去年から続きスポンサーをさせていただきます。`}</p>
    <p>{`今年は会場も東京国際フォーラムになるということで、メドレーも去年よりもさらにアクティブな形でイベントに参加させていただくことになりそうです。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`ここまでご紹介させていただいたイベント以外にも現在、スポンサーの打診をさせていただいているイベントがいくつかありますが、こちらも決定次第おしらせできればと考えています。`}</p>
    <p>{`メドレーでは、技術や業界の発展に少しでも寄与できればという考えから、エンジニア・デザイナーの技術イベントなどにこれからも積極的に協賛させていただくスタンスを取っています。`}</p>
    <p>{`全てのイベントに協賛できるわけではありませんが、スポンサーを探しているイベント運営者の方がいらっしゃいましたら、一度お気軽にお問い合わせいただければと思いますので、よろしくお願いします。`}</p>
    <p>{`▼ メドレーってどんな会社？気になった方はこちら`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/team/"
      }}>{`https://www.medley.jp/team/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      